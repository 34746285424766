import React, { Suspense, useState } from "react";
import axios from "axios";
import env from "react-dotenv";
import { Box, CircularProgress } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

const Navbar = React.lazy(() => import("./components/Navbar"));
const NotFound = React.lazy(() => import("./components/NotFound"));
const Home = React.lazy(() => import("./components/Home"));
const Courses = React.lazy(() => import("./components/Courses"));
const CreateCourse = React.lazy(() => import("./components/CreateCourse"));
const Course = React.lazy(() => import("./components/Course"));
const CreateLesson = React.lazy(() => import("./components/CreateLesson"));
const SignIn = React.lazy(() => import("./components/SignIn"));
const SignUp = React.lazy(() => import("./components/SignUp"));
const ServerError = React.lazy(() => import("./components/ServerError"));
const Footer = React.lazy(() => import("./components/Footer"));

function App() {
  axios.defaults.baseURL = env.API_URL;

  const [loginData, setLoginData] = useState(
    localStorage.getItem("loginData") ? localStorage.getItem("loginData") : null
  );

  return (
    <Box
      sx={{ paddingBottom: "60px", position: "relative", minHeight: "100vh" }}
    >
      <CssBaseline />
      <Suspense
        fallback={
          <CircularProgress sx={{ marginLeft: "50%", marginTop: "50%" }} />
        }
      >
        <Navbar loginData={loginData} setLoginData={setLoginData} />
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/courses/create" element={<CreateCourse />} />
          <Route path="/courses/:courseSlug" element={<Course />} />
          <Route
            path="/courses/:courseSlug/create"
            element={<CreateLesson />}
          />
          <Route path="/sign-in" element={<SignIn setLoginData={setLoginData} />} />
          <Route
            path="/sign-up"
            element={
              <SignUp loginData={loginData} setLoginData={setLoginData} />
            }
          />
          <Route path="/error" element={<ServerError />} />
        </Routes>
        <Footer />
      </Suspense>
    </Box>
  );
}

export default App;
